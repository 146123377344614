@staticBaseUrl: "https://cdn.beatsense.com/public";
@import (reference) "../../../less/variables";
@import (reference) "../../../less/general";

#stationsHomePage {
  margin: 40px auto 0;
  text-align: center;

  article{
    width: 60vw;
    margin: 0 auto;
  }
  .comingSoon {
    text-align: center;
    font-size: 3em;
    margin-top: 100px;
    .logoFont;
  }

  section {
    margin: 40px 0;
  }

  #slogan {
    background-image: radial-gradient(at 3% 95%, @color0_dark 0, transparent 96%), radial-gradient(at 73% 17%, @color2_dark 0, transparent 84%), radial-gradient(at 73% 17%, @stationsColor0 0, transparent 84%);
    h1{
      .biggerText;
      color: white;
      margin-bottom: 6px;
      padding: 12px 0 25px;
      > div{
        position: relative;
      }
      div:nth-child(1){
        right: 15px;
        margin-bottom: 8px;
      }
      div:nth-child(2){
        left: 25px;
      }
    }
    .brandUnderline{
      margin-top: 4px;
    }
  }

  #myStations{
    button{
      padding: 0;
      margin-top: -10px;
      a{
        height: 100%;
        padding: 10px 17px 12px;
        display: block;
        font-weight: bold;
        &:hover{
          text-decoration: none;
        }
      }
    }
  }
  .bubblesContainer {
    display: flex;
    justify-content: center;
    margin: 35px 0;
    flex-wrap: wrap;
  }

  #stationsSearch {
    h2{
      margin-bottom: 30px;
    }
    label{
      font-weight: bold;
      margin-right: 8px;
      .bigText;
    }
    .bubblesContainer{
      margin-bottom: 15px;
    }
    #stationsSearchInput{
      display: flex;
      #stationsSearchButton{
        height: 40px;
        .mediumText;
        z-index: 1;
        margin-left: -40px;
        background-color: @color0;
        position: relative;
        top: 5px;
      }
    }

    #mixtapesCheckbox{
      margin-left: 8px;
      input{
        margin-right: 2px;
      }
    }
    .musicTagSearch {
      width: 300px;
      .tags{
        border-radius: 50px;
        display: flex;
        align-items: center;
        input{
          height: 32px;
        }
        .tag-list{
          .tag-item{
            border-radius: 20px;
            display: flex;
            align-items: center;
            padding: 15px 9px 17px;
            background-color: @color0 !important;
          }
        }
      }
    }
  }

  .brandUnderline {
    width: 100%;
    height: 4px;
    background-color: @color2;
    box-shadow: 4px 2px 1px @color0;
    opacity: 0.8;
    position: relative;
    left: 15px;
    transform: skew(18deg);

    &.left {
      left: -15px;
    }
  }

  h1, h2 {
    display: inline-grid;
  }
  h2{
    .giantText;
    position: relative;
    margin-bottom: 25px;
  }
}