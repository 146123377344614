@staticBaseUrl: "https://cdn.beatsense.com/public";
@import (reference) "../../../../less/variables";
.stationBubble {
	@bubbleSize: 90px;
	position: relative;
	width: @bubbleSize;
	height: @bubbleSize;
	bottom: 10px;
	transition: scale 0.3s ease-in-out;
	margin: 13px 15px;
	a{
		height: inherit;
		display: block;
		.flex();
		.bubbleCenterInfo{
			position: absolute;
			top: 0;
			left: 0;
			z-index: -1;
			width: @bubbleSize;
			height: @bubbleSize;
			.flex();
			color: white;
			.privateInfoBG{
				background-color: @glassBg_medium;
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				right: 0;
				z-index: 1;
				border-radius: 50%;
			}
			img{
				width: 90px;
				height: 90px;
				margin: 0px; //override userImage
				position: absolute;
				right: 0;
				top: 0px;
			}
			.privateRoomDetails{
				position: relative;
				bottom: 4px;
				z-index: 1;
				.fa-lock{
					color: @color0_light;
				}
			}
		}
		.numberBubble{
			position: absolute;
			top: 1px;
			right: 3px;
			background-color: @textColor;
			color: white;
			padding-top: 2px;
			.smallText;
			padding: 9px 8px 8px 9px;
		}
		.bubbleBottomInfo{
			position: absolute;
			top: calc(@bubbleSize - 25px);
			display: flex;
			flex-direction: column;
			align-items: center;
			.bubbleLabel {
				padding: 0px 4px;
				line-height: 15px;
				.smallText;
				border-radius: 2px;
			}
			.stationName{
				display: inline-block;
				background-color: @stationsColor0;
				color: white;
				.bubbleLabel();
				width: fit-content;
			}
			.tags{
				display: flex;
				width: 110px;
				flex-wrap: wrap;
				justify-content: center;
				> div{
					.bubbleLabel();
					margin-right: 0;
					display: inline-block;
					max-width: @bubbleSize;
					.ellipsis;
					&:nth-child(2n+1){
						background-color: @color0;
					}
				}
			}
		}
	}

	&:hover{
		scale: 1.2;
	}
	&.mediumBubble{
		scale: 1.2;
		bottom: -5px;
		margin: 13px 20px;
		a .bubbleBottomInfo{
			scale: 0.9;
		}
		&:hover{
			scale: 1.4;
		}
	 }
	&.bigBubble{
		scale: 1.5;
		bottom: 0px;
		margin: 26px 25px;
		a .bubbleBottomInfo{
			scale: 0.75;
		}
		&:hover{
			scale: 1.7;
		}
	}
	&.onlineInfluencer {
		border: 2px solid @green3;
		border-radius: 50%;
	}

	.fa-lock {
		z-index: 1;
	}
}
.bubblesContainer{
	> *{
		position: relative;
	}
	> :nth-child(2n) {
		bottom: 12px !important;
	}

	> :nth-child(2n+1) &.mediumBubble{
		bottom: -6px !important;
	}
	> :nth-child(2n+1) &.bigBubble{
		bottom: -3px !important;
	}
}