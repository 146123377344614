.stationBubble {
  position: relative;
  width: 90px;
  height: 90px;
  bottom: 10px;
  transition: scale 0.3s ease-in-out;
  margin: 13px 15px;
}
.stationBubble a {
  height: inherit;
  display: block;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}
.stationBubble a .bubbleCenterInfo {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 90px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  color: white;
}
.stationBubble a .bubbleCenterInfo .privateInfoBG {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  border-radius: 50%;
}
.stationBubble a .bubbleCenterInfo img {
  width: 90px;
  height: 90px;
  margin: 0px;
  position: absolute;
  right: 0;
  top: 0px;
}
.stationBubble a .bubbleCenterInfo .privateRoomDetails {
  position: relative;
  bottom: 4px;
  z-index: 1;
}
.stationBubble a .bubbleCenterInfo .privateRoomDetails .fa-lock {
  color: #97daf7;
}
.stationBubble a .numberBubble {
  position: absolute;
  top: 1px;
  right: 3px;
  background-color: #404040;
  color: white;
  padding-top: 2px;
  font-size: 0.9rem;
  padding: 9px 8px 8px 9px;
}
.stationBubble a .bubbleBottomInfo {
  position: absolute;
  top: calc(90px - 25px);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.stationBubble a .bubbleBottomInfo .bubbleLabel {
  padding: 0px 4px;
  line-height: 15px;
  font-size: 0.9rem;
  border-radius: 2px;
}
.stationBubble a .bubbleBottomInfo .stationName {
  display: inline-block;
  background-color: black;
  color: white;
  padding: 0px 4px;
  line-height: 15px;
  font-size: 0.9rem;
  border-radius: 2px;
  width: fit-content;
}
.stationBubble a .bubbleBottomInfo .tags {
  display: flex;
  width: 110px;
  flex-wrap: wrap;
  justify-content: center;
}
.stationBubble a .bubbleBottomInfo .tags > div {
  padding: 0px 4px;
  line-height: 15px;
  font-size: 0.9rem;
  border-radius: 2px;
  margin-right: 0;
  display: inline-block;
  max-width: 90px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.stationBubble a .bubbleBottomInfo .tags > div:nth-child(2n+1) {
  background-color: #2196F3;
}
.stationBubble:hover {
  scale: 1.2;
}
.stationBubble.mediumBubble {
  scale: 1.2;
  bottom: -5px;
  margin: 13px 20px;
}
.stationBubble.mediumBubble a .bubbleBottomInfo {
  scale: 0.9;
}
.stationBubble.mediumBubble:hover {
  scale: 1.4;
}
.stationBubble.bigBubble {
  scale: 1.5;
  bottom: 0px;
  margin: 26px 25px;
}
.stationBubble.bigBubble a .bubbleBottomInfo {
  scale: 0.75;
}
.stationBubble.bigBubble:hover {
  scale: 1.7;
}
.stationBubble.onlineInfluencer {
  border: 2px solid #4CAF50;
  border-radius: 50%;
}
.stationBubble .fa-lock {
  z-index: 1;
}
.bubblesContainer > * {
  position: relative;
}
.bubblesContainer > :nth-child(2n) {
  bottom: 12px !important;
}
 > :nth-child(2n+1) .bubblesContainer.mediumBubble {
  bottom: -6px !important;
}
 > :nth-child(2n+1) .bubblesContainer.bigBubble {
  bottom: -3px !important;
}
