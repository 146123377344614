@bgColor:#e6e6e6;
@bgColor_transparent: rgba(238, 238, 238, 0.8);
@bgColor_lighter: #f8f8f8;
@bgColor_hover: #dfdfdf;
@bgColor2: rgba(0,0,0,0.5);
@whiteBg_transparent: rgba(255,255,255,0.9);
@border: 1px solid #D2D2D2;
@textColor: #404040;
@borderRadius: 20px;
@spacing: 20px;

@glassBg: rgba(0, 0, 0, 0.4);
@glassBg_medium: rgba(0, 0, 0,0.5);
@glassBg_darker: rgba(0, 0, 0, 0.6);
@glassBg_evenDarker: rgba(0, 0, 0, 0.8);

@black_transparent: rgba(0,0,0,0.6);

@homepageWidth: 1150px;

@playerWidth: 585px;
@playerHeight: 329px;

@mobileChatHeight: 45vh;

.gradient-generator( @startColor: @gradColor1, @endColor: @gradColor2, @alpha: 80%, @startPoint:bottom, @endPoint:left ){
  background: -webkit-linear-gradient(@startPoint @endPoint, fade(@startColor,@alpha), fade(@endColor,@alpha));
  background: -o-linear-gradient(@startPoint @endPoint,  fade(@startColor,@alpha), fade(@endColor,@alpha));
  background: -moz-linear-gradient(@startPoint @endPoint,fade(@startColor,@alpha), fade(@endColor,@alpha));
  background: linear-gradient(to @startPoint @endPoint,  fade(@startColor,@alpha), fade(@endColor,@alpha));
}

.bg-transition(){
  -webkit-transitiobtn: background-color 0.35ws;
  -moz-transition: background-color 0.35s;
  -o-transition: background-color 0.35s;
  transition: background-color 0.35s;
}

@color0: #2196F3;
@color0_subtle: rgba(33, 150, 243,0.2);
@color0_transparent: rgba(33, 150, 243,0.8);
@color0_transparent_more: rgba(33, 150, 243,0.5);
@color0_light: #97daf7;
@color0_lightest: #b0e7ff;
@color1: #3D3C49;
@color1_transparent: rgba(43, 58, 66, 0.75);
@color0_dark: darken(@color0, 10%);
@color0_dark_transparent: rgba(54, 162, 209, 0.8);
@color0_txt: rgb(68, 176, 171);
@color2: rgb(243,33,150);
@color2_light: lighten(@color2, 10%);
@color2_dark:  darken(@color2, 10%);
@color2_transparent: rgba(249, 44, 243,0.9);
//#7b4262
@color4: #79A2A1;
@stationsColor0: black;
@red: #f53c56;
@red2: #FF8181;
@youtube-red: #CC181E;
@spotify-green: #2EBD59;
@green: #219820;
@green2: #A7FFA6;
@green3: #4CAF50;

@grayText: #6f6f6f;
@green4: #55DA96;
@green4_dark: #53bd87;
@gray: #333;
@lightGray: #eaeaea;
@facebookBlue: #4A66A0;
@stripesBg: #ececec;
@stripesBgHover: #E3EEEE;
@twitter: #00aced;
@googlePlus: #D54934;
@onlineGreen: #39e737;
@beats: #eccc1b;

@fontFamily: 'Poppins', Calibri, sans-serif;

.flex{
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}

.ellipsis{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/*Animations*/

.noblink {
  -webkit-animation-iteration-count: 0 !important;
  animation-iteration-count: 0 !important;
}

.rotate-90 {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.rotate-180 {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.flip {
  -moz-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  transform: scaleX(-1);
  -ms-filter: fliph; /*IE*/
  filter: fliph; /*IE*/
}

.clickable{
  cursor: pointer;
}

.notclickable {
    text-decoration: none !important;
    cursor: default !important;
}

.unlink {
  text-decoration: none !important;
}

.grid {
  > * {
    width: 20%;
    display: inline-block;
  }
  @media screen and (max-width: 1600px) {
    > * {
      width: 25%;
    }
  }
  @media screen and (max-width: 1250px) {
    > * {
      width: 33%;
    }
  }
  @media screen and (max-width: 950px) {
    > * {
      width: 50%;
    }
  }
}


.giantText{
  font-size: 1.8rem;
}
.biggerText{
  font-size: 1.6rem;
}
.bigText{
  font-size: 1.4rem;
}
.mediumText{
  font-size: 1.2rem;
}
.normalText{
  font-size: 1.1rem;
}
.semiSmallText{
  font-size: 0.95rem;
}
.smallText{
  font-size: 0.9rem;
}
.smallerText{
  font-size: 0.8rem;
}
.tinyText{
  font-size: 0.75rem;
}
.smallestText{
  font-size: 0.65rem;
}
