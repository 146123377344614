.stationsPopup .modal-content {
  max-width: 1150px !important;
}
#stationsPopup .modal-body #searchHolder {
  display: flex;
  align-items: center;
}
#stationsPopup .modal-body #searchHolder *:first-child {
  flex-grow: 2;
}
#stationsPopup .modal-body #searchHolder *:first-child .tags {
  border-radius: 20px;
  height: 38px;
  margin-right: 8px;
}
#stationsPopup .modal-body #searchHolder *:first-child .tags input {
  height: 29px;
}
#stationsPopup .modal-body #searchHolder .homeColumnSearch {
  flex-grow: 2;
}
#stationsPopup .modal-body #searchHolder .filterFavorites {
  font-size: 1.4rem;
  margin-left: 10px;
}
#stationsPopup .modal-body .listHolder {
  max-height: 80vh;
  display: flex;
  flex-wrap: wrap;
  margin-top: 25px;
}
#stationsPopup .modal-body .listHolder .roomTile {
  margin-right: 25px;
  margin-bottom: 10px;
}
#stationsPopup .modal-body .listHolder .roomTile .roomTileHolder {
  margin-bottom: 15px;
}
#stationsPopup .modal-body #noRooms {
  text-align: center;
  margin-top: 15px;
}
#stationsPopup .modal-body #noRooms b {
  font-weight: 100;
}
#stationsPopup .modal-body #noRooms a {
  text-decoration: underline;
  color: #0c7cd5;
}
