#stationsHomePage {
  margin: 40px auto 0;
  text-align: center;
}
#stationsHomePage article {
  width: 60vw;
  margin: 0 auto;
}
#stationsHomePage .comingSoon {
  text-align: center;
  font-size: 3em;
  margin-top: 100px;
  font-family: 'OleoScript', cursive;
}
#stationsHomePage section {
  margin: 40px 0;
}
#stationsHomePage #slogan {
  background-image: radial-gradient(at 3% 95%, #0c7cd5 0, transparent 96%), radial-gradient(at 73% 17%, #d50c7c 0, transparent 84%), radial-gradient(at 73% 17%, black 0, transparent 84%);
}
#stationsHomePage #slogan h1 {
  font-size: 1.6rem;
  color: white;
  margin-bottom: 6px;
  padding: 12px 0 25px;
}
#stationsHomePage #slogan h1 > div {
  position: relative;
}
#stationsHomePage #slogan h1 div:nth-child(1) {
  right: 15px;
  margin-bottom: 8px;
}
#stationsHomePage #slogan h1 div:nth-child(2) {
  left: 25px;
}
#stationsHomePage #slogan .brandUnderline {
  margin-top: 4px;
}
#stationsHomePage #myStations button {
  padding: 0;
  margin-top: -10px;
}
#stationsHomePage #myStations button a {
  height: 100%;
  padding: 10px 17px 12px;
  display: block;
  font-weight: bold;
}
#stationsHomePage #myStations button a:hover {
  text-decoration: none;
}
#stationsHomePage .bubblesContainer {
  display: flex;
  justify-content: center;
  margin: 35px 0;
  flex-wrap: wrap;
}
#stationsHomePage #stationsSearch h2 {
  margin-bottom: 30px;
}
#stationsHomePage #stationsSearch label {
  font-weight: bold;
  margin-right: 8px;
  font-size: 1.4rem;
}
#stationsHomePage #stationsSearch .bubblesContainer {
  margin-bottom: 15px;
}
#stationsHomePage #stationsSearch #stationsSearchInput {
  display: flex;
}
#stationsHomePage #stationsSearch #stationsSearchInput #stationsSearchButton {
  height: 40px;
  font-size: 1.2rem;
  z-index: 1;
  margin-left: -40px;
  background-color: #2196F3;
  position: relative;
  top: 5px;
}
#stationsHomePage #stationsSearch #mixtapesCheckbox {
  margin-left: 8px;
}
#stationsHomePage #stationsSearch #mixtapesCheckbox input {
  margin-right: 2px;
}
#stationsHomePage #stationsSearch .musicTagSearch {
  width: 300px;
}
#stationsHomePage #stationsSearch .musicTagSearch .tags {
  border-radius: 50px;
  display: flex;
  align-items: center;
}
#stationsHomePage #stationsSearch .musicTagSearch .tags input {
  height: 32px;
}
#stationsHomePage #stationsSearch .musicTagSearch .tags .tag-list .tag-item {
  border-radius: 20px;
  display: flex;
  align-items: center;
  padding: 15px 9px 17px;
  background-color: #2196F3 !important;
}
#stationsHomePage .brandUnderline {
  width: 100%;
  height: 4px;
  background-color: #f32196;
  box-shadow: 4px 2px 1px #2196F3;
  opacity: 0.8;
  position: relative;
  left: 15px;
  transform: skew(18deg);
}
#stationsHomePage .brandUnderline.left {
  left: -15px;
}
#stationsHomePage h1,
#stationsHomePage h2 {
  display: inline-grid;
}
#stationsHomePage h2 {
  font-size: 1.8rem;
  position: relative;
  margin-bottom: 25px;
}
